import React, { useRef } from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import {
  ContentfulComposePageType,
  ContentfulSlotPageType,
} from "../types/contentful.types"
import BackgroundContainer from "../components/background-container"
import Footer from "../components/footer"
import DownloadPopup from "../components/download-popup"
import { useState } from "react"
import MenuMobile from "../components/menu/menu-mobile"
import Menu from "../components/menu/menu"
import Sections from "../components/sections/sections"
import { StaticImage } from "gatsby-plugin-image"
import AdjustLink from "../components/adjust-link"
import { isMobile, isTablet } from "react-device-detect"

export const query = graphql`
  query SlotPageTemplateQuery($slug: String!) {
    contentfulSlotPage(compose__page: { elemMatch: { slug: { eq: $slug } } }) {
      compose__page {
        title
        impressionLink {
          impressionLink
        }
        seo {
          keywords
          title
          no_index
          no_follow
          description
          socialImage {
            file {
              url
            }
          }
        }
      }
      gameIframeUrl
      slotInfoLink
      sections {
        columns {
          __typename
          ... on ContentfulComponentSlotInfoTable {
            id
            tabTitle
            text {
              raw
            }
            software
            rtp
            reels
            paylines
            maxWager
            maxPayout
            jackpotType
            theme
            bonusRound
            jackpotTypeLinkTo {
              id
              compose__page {
                slug
              }
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1920, quality: 20)
                }
              }
              title
            }
          }
          ... on ContentfulComponentSlotsWithFilter {
            id
            internalName
            slots {
              slotIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 330)
                  }
                }
              }
              gameIframeUrl
              slotInfoLink
              compose__page {
                title
                slug
              }
              categories {
                id
                title
              }
            }
          }
          ... on ContentfulComponentSlotPageCards {
            id
            internalName
          }
          ... on ContentfulComponentSlotsTypes {
            id
            __typename
            slots {
              id
              compose__page {
                slug
                title
              }
              categories {
                title
                id
              }
              gameIframeUrl
              slotIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                title
              }
              description {
                raw
              }
            }
          }
          ... on ContentfulComponentGallery {
            id
            __typename
            galleryType
            title
            media {
              title
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1920, quality: 20)
                }
              }
            }
          }
          ... on ContentfulComponentTextImage {
            id
            tabTitle
            __typename
            text {
              raw
              references {
                ... on ContentfulComponentImageLink {
                  id
                  __typename
                  contentful_id
                  title
                  linkToUrl
                  openInNewWindow
                  image {
                    localFile {
                      publicURL
                    }
                  }
                  linkToInnerPage {
                    slug
                  }
                }
                ... on ContentfulComponentStores {
                  id
                  contentful_id
                  __typename
                  storesToShow
                }
              }
            }
            textBg
            imagesSide
            gallery {
              media {
                id
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1920, quality: 60)
                  }
                }
                title
                file {
                  contentType
                  url
                }
              }
            }
          }
          ... on ContentfulComponentSlotsTypes {
            id
            __typename
            showDescription
          }
          ... on ContentfulComponentCards {
            id
            title
            tabTitle
            additionalText {
              raw
            }
            bottomText {
              raw
            }
            iconPlace
            showTitles
            backgroundColor
            itemsInRow
            cards {
              title
              iconSizeOnMobile
              readMore {
                compose__page {
                  slug
                }
              }
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 80)
                  }
                }
                title
              }
              textAlignment
              textColor
              text {
                raw
              }
            }
          }
          ... on ContentfulComponentTextImageGroup {
            id
            __typename
            title
            spaceBetween
            description {
              raw
            }
            bottomText {
              raw
            }
            textImages {
              text {
                raw
                references {
                  ... on ContentfulComponentImageLink {
                    id
                    __typename
                    contentful_id
                    title
                    linkToUrl
                    openInNewWindow
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    linkToInnerPage {
                      slug
                    }
                  }
                  ... on ContentfulComponentStores {
                    contentful_id
                    __typename
                    storesToShow
                  }
                }
              }
              textBg
              imagesSide
              gallery {
                media {
                  id
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 1920, quality: 85)
                    }
                  }
                  title
                  file {
                    contentType
                    url
                  }
                }
              }
            }
          }
          ... on ContentfulComponentSimpleText {
            id
            tabTitle
            backgroundColor
            text {
              raw
              references {
                ... on ContentfulComponentImageLink {
                  id
                  __typename
                  contentful_id
                  title
                  linkToUrl
                  openInNewWindow
                  image {
                    localFile {
                      publicURL
                    }
                  }
                  linkToInnerPage {
                    slug
                  }
                }
              }
            }
          }
        }
      }
      hero {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          title
          file {
            contentType
            url
          }
        }
        text {
          raw
        }
      }
    }
  }
`

const SlotPageTemplate = ({ data }) => {
  const { seo, title, impressionLink }: ContentfulComposePageType =
    data.contentfulSlotPage.compose__page[0]
  const {
    hero,
    sections,
    gameIframeUrl,
    slotInfoLink,
  }: ContentfulSlotPageType = data.contentfulSlotPage
  const [showDownloadPopup, setShowDownloadPopup] = useState<boolean>(false)

  const iframeContainerRef = useRef<any>()
  const onFullscreenClick = () => {
    iframeContainerRef.current.classList.add("fullscreen")
  }

  const onCloseClick = () => {
    iframeContainerRef.current.classList.remove("fullscreen")
  }

  seo["slotInfoCardsTitle"] = title.toUpperCase().replace("SLOT MACHINE", "")

  return (
    <>
      <SEO data={seo} impressionLink={impressionLink?.impressionLink} />

      <DownloadPopup
        onCloseClick={() => setShowDownloadPopup(false)}
        show={showDownloadPopup}
      />

      <div className='app-container'>
        <MenuMobile onDownloadClick={() => setShowDownloadPopup(true)} />
        <Menu onDownloadClick={() => setShowDownloadPopup(true)} />
        <main className='slot-page'>
          <BackgroundContainer
            className='slot-info-top-background'
            image={hero.image}
          >
            <div
              className={`inner-container hero-content pt-10 xl:pt-0 px-10`}
            >
              <div className='block xl:hidden mb-5'>
                <Link
                  className='text-2xl text-[#012043] hover:text-[#094385]'
                  to='/slot-games'
                >
                  {'< All Slots'}
                </Link>
              </div>

              <h1 className='self-start text-[45px] pb-10 text-white font-bold'>
                {title}
              </h1>
            </div>
            <div className={`xl:pt-0 xl:px-10${
                gameIframeUrl
                  ? ' pb-14 xl:pb-52'
                  : ' pb-10'
              }`}>
              {gameIframeUrl && (
                <div className='slot-game-hero-container flex flex-col xl:flex-row xl:space-x-16 space-y-10 xl:space-y-0'>
                  <div className='flex-1'>
                    <div className='flex flex-col h-full'>
                      <div
                        ref={iframeContainerRef}
                        className='iframe-container relative min-h-[600px] xl:min-h-0'
                      >
                        <div className='full-screen-icon'>
                          <button onClick={onFullscreenClick}>
                            <StaticImage
                              src='../images/full-screen.png'
                              alt='Close Popup'
                              width={60}
                            />
                          </button>
                        </div>
                        <button onClick={onCloseClick} className='slot-game-x'>
                          X
                        </button>
                        <iframe
                          src={gameIframeUrl}
                          className='w-full flex-1 relative z-10'
                          scrolling='no'
                        />
                      </div>
                      <div className='slot-game-play-for-real p-5 text-center'>
                        <div className='text-white text-[20px] xl:text-[35px] font-semibold'>
                          READY TO PLAY FOR REAL?
                        </div>
                        {isMobile || isTablet ? (
                          <AdjustLink slotPage={true}>
                            <StaticImage
                              className='cursor-pointer slot-info-get-the-app-button'
                              src='../images/get-the-app-button.png'
                              alt=''
                              width={250}
                            />
                          </AdjustLink>
                        ) : (
                          <button onClick={() => setShowDownloadPopup(true)}>
                            <StaticImage
                              className='cursor-pointer slot-info-get-the-app-button'
                              src='../images/get-the-app-button.png'
                              alt=''
                              width={250}
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='slot-game-more-games'>
                    <div className='text-[20px] text-left xl:text-[26px] xl:max-w-[200px] xl:mx-10 xl:text-center'>
                      What will you play next?
                    </div>
                    <div className='mote-games-items flex xl:flex-col space-x-5 xl:space-x-0 xl:space-y-10 justify-center items-center'>
                      <div className='more-game-item'>
                        <Link to='/slot-games/forbidden-dragons'>
                          <StaticImage
                            src='../images/slots/ForbiddenDragons.png'
                            alt=''
                            width={160}
                          />
                          <div className='more-game-item-name'>
                            Forbidden Dragons
                          </div>
                        </Link>
                      </div>
                      <div className='more-game-item'>
                        <Link to='/slot-games/invaders-from-the-planet-moolah'>
                          <StaticImage
                            src='../images/slots/InvadersFromThePlanetMoolah.png'
                            alt=''
                            width={160}
                          />
                          <div className='more-game-item-name'>
                            Invaders From The Planet Moolah
                          </div>
                        </Link>
                      </div>
                      <div className='more-game-item'>
                        <Link to='/slot-games/zeus-ii'>
                          <StaticImage
                            src='../images/slots/ZeusII.png'
                            alt=''
                            width={160}
                          />
                          <div className='more-game-item-name'>Zeus II</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </BackgroundContainer>
          {slotInfoLink && (
            <Sections
              createTabs={true}
              seo={seo}
              sections={sections}
              onDownloadClick={() => setShowDownloadPopup(true)}
            />
          )}
        </main>

        <Footer />
      </div>
    </>
  )
}

export default SlotPageTemplate
